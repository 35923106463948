import "../App.css";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import store from "../store/index";
import Notification from "../components/notification";
const EditProfileModal = ({user, modalOpen, setmodalOpen, NotifySuccess}) => {
    const [Loading, setLoading] = useState(false);
    const [NotifyFailed, setNotifyFailed] = useState({ status: false, message: "" });
    const { id } = useParams();
    const [Username, setUsername] = useState({ username: id ?? "" });
    const NewLink = () => {
        if (window.confirm('NOTE: Your current anonymous link will be revoked, therefore you need to share your friends the new link...')) {
            store
                .post({
                    endpoint: "generate-anonymous-link",
                    details: {},
                })
                .then((data) => {
                    var user_details = data.data.data;
                    var result = encodeURIComponent(JSON.stringify(user_details));
                    localStorage.setItem("user", result);
                    window.location.href = "/" + user_details.username
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);

                    var error_message = error.response.data.message;
                    setNotifyFailed({
                        ...NotifyFailed,
                        status: true,
                        message: error_message,
                    });
                    setTimeout(() => {
                        setNotifyFailed({
                            ...NotifyFailed,
                            status: false,
                        });
                    }, 3000);

                    setLoading(false);
                });
        }

    };
    const UpdateUsername = () => {
        setLoading(true);
        alert('NOTE: your anonymous link will also be changed')
        store
            .post({
                endpoint: "update-details",
                details: Username,
            })
            .then((data) => {
                var user_details = data.data.data;
                var result = encodeURIComponent(JSON.stringify(user_details));
                localStorage.setItem("kaffir_user", result);
                setLoading(false);
                window.location.href = "/" + user_details.username

            })
            .catch((error) => {
                setLoading(false);

                var error_message = error.response.data.message;
                setNotifyFailed({
                    ...NotifyFailed,
                    status: true,
                    message: "An error occured or Username is not available ",
                });
                setTimeout(() => {
                    setNotifyFailed({
                        ...NotifyFailed,
                        status: false,
                    });
                }, 3000);

                setLoading(false);
            });
    };
    return (
        <>
            <div
                id="authentication-modal"
                tabIndex="-1"
                aria-hidden="true"
                className={modalOpen + " overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bg-[#00000069] z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center"}
            >
                <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                    <div className="relative bg-white rounded-lg shadow 0">
                        <Notification notifyFailed={NotifyFailed} notifySuccess={NotifySuccess} />

                        <button
                            onClick={() => setmodalOpen("hidden")}
                            readOnly
                            type="button"
                            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center ray-800 -white"
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>
                        <div className="py-6 px-6 lg:px-8">
                            <img src={require("../assets/img/anonymous.png")} className="w-[90px]"></img>
                            <h3 className="mb-4 text-xl font-medium text-gray-900 ">Edit your profile details</h3>
                            <form
                                className="space-y-6"
                                method="post"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    UpdateUsername();
                                }}
                            >
                                <div>
                                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 300">
                                        Your Anonymous Address
                                    </label>
                                    <div className="flex">
                                        {user && (
                                            <input
                                                type="email"
                                                name="email"
                                                value={user.anonymous_link}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 0 y-500 r-gray-400 "
                                                placeholder="name@company.com"
                                                required=""
                                                readOnly
                                            />
                                        )}
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                NewLink();
                                            }}
                                            type="submit"
                                            className="w-[2] ml-2 text-white bg-kaffirbtn hover:bg-kaffirpurple focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  0 lue-700 -blue-800"
                                        >
                                            New
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="password" className="justify-between flex mb-2 text-sm font-medium text-gray-900 300">
                                        New Username: {Username.username} <span>Old username: {user.username}</span>
                                    </label>
                                    <input
                                        maxLength={10}
                                        type="text"
                                        value={Username.username}
                                        onChange={(e) => {
                                            setUsername({ ...Username, username: e.target.value.replace(/\s/g, '') });
                                        }}
                                        placeholder="New Usernaame"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 0 y-500 r-gray-400 "
                                        required
                                    />
                                </div>

                                <button
                                    type="submit"
                                    className="w-full text-white bg-kaffirbtn hover:bg-kaffirpurple focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  0 lue-700 -blue-800"
                                >
                                    {!Loading && "Save"}
                                    {Loading && "saving...."}
                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditProfileModal;