import useGetItem from "../store/getItem";
import "../App.css";
import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import store from "../store/index";
import Notification from "../components/notification";
import EditProfileModal from "../components/editProfileModal";

const Profile = () => {
    let navigate = useNavigate();
    const { itemdata: user } = useGetItem("kaffir_user");
    const [modalOpen, setmodalOpen] = useState("hidden");
    const [Loading, setLoading] = useState(false);
    const [NotifySuccess, setNotifySuccess] = useState({ status: false, message: "" });
    const [NotifyFailed, setNotifyFailed] = useState({ status: false, message: "" });
    const { id } = useParams();

    if (user !== null) {
        if (id !== "dashboard" && id !== user.username) {
            window.location.href = "/";
        }
    }
    const copyToClipboard = () => {
        var copyText = document.getElementById("anonLink");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        setTimeout(async () => await window.navigator.clipboard.writeText(copyText.value), 2000);
        alert("Copied the text: " + copyText.value);
    };

    const logout = () => {
        localStorage.removeItem("kaffir_user");
        localStorage.removeItem("kaffir_token");
        navigate("/signin");
    };
    const verifyEmail = () => {
        setLoading(true);
        store.post({
            endpoint: "requestVerifyEmail/" + user.user_token,
            details: {},
        }).then((resp) => {
            setNotifySuccess({ status: true, message: "Please check your mail for  Email verification link" })
            setLoading(false);

            console.log(resp);
        }).catch((error) =>{
            setNotifyFailed({ status: true, message: "Unable to send Email verification link. Please try again" });
            setLoading(false);
        })
    }

    return (
        <div className="h-[100vh] m-0 pt-0 grid md:grid-cols-2 blue-sm md:bg-white bg-kaffirpurple ">
            <Notification notifyFailed={NotifyFailed} notifySuccess={NotifySuccess} />

            <div className="h-[100%] bg-kaffirpurple flex justify-center flex-col items-center hide-lg">
                <img src={require("../assets/img/welcomeblank.png")} className="w-[500px]" alt="logoage"></img>
                {/* <div className="text-center border-2 border-white rounded-lg text-white text-2xl py-2 relative bottom-[90px] px-3">
                    <p>Welcome {user && user.username}</p>
                </div> */}
            </div>
            <div className="h-full bg-greeen flex justify-center items-center mx-2 ">
                <div className=" w-[95%] sm:w-[400px]   shadow-sm-light shadow-[#26066f] mx-auto h-[600px] rounded-lg  card-jump-2 bg-white urple my-3 pt-9 py-4">
                    <img src={require("../assets/img/anonymous.png")} className="w-[100px] block mx-auto"></img>

                    {user && (
                        <div className="mx-auto text-kaffirpurple  ">
                            <div className="text-center">
                                <p className="capitalize">{user.username}'s Profile</p>
                                <div className="flex justify-center items-center text-sm my-2">
                                    <div className="bg-kaffirbtn h-2 w-2 rounded-[50%] mx-1"></div>
                                    <div onClick={() => copyToClipboard()}>
                                        <i className="fa-solid fa-copy text-lg "></i>
                                    </div>
                                    <input id="anonLink" value={user.anonymous_link} onChange={() => {}} className=" bg-transparent"></input>
                                    <div className="bg-kaffirbtn h-2 w-2 rounded-[50%] mx-1"></div>
                                </div>
                                <div className="flex justify-center items-center mt-1 my-6">
                                    <button className="block my-1 border-kaffirpurple firbtn border mr-3 text-kaffirpurple  rounded py-1 px-4 hover:bg-kaffirpurple hover:text-white  text-xs" onClick={() => copyToClipboard()}>
                                        Copy Link
                                    </button>
                                    <button className="block my-1 border-kaffirpurple firbtn border mr-3 text-kaffirpurple  rounded py-1 px-4 hover:bg-kaffirpurple hover:text-white  text-xs" onClick={() => setmodalOpen("flex")}>
                                        Edit Profile
                                    </button>
                                </div>

                                <p className="text-xs px-[30px]">Share your profile link to get responses from your friends. Go to “view messages” to check out responses</p>
                                <a
                                    href="/responses"
                                    id="createGroup"
                                    className="mt-2 pointer hover:bg-white shadow-white btn-gradient border-none border-kaffirpurple firbtn text-white text-sm rounded-[5px] focus:ring-blue-500 focus:border-blue-500 block w-[80%]  mx-auto p-3"
                                >
                                    View Messages
                                </a>

                                <div className="my-[30px]">
                                    <p className="ml-auto my-2 block">Share on social media</p>
                                    <div className="flex mx-auto w-[100%] justify-center ">
                                        <div className="bg-kaffirbtn hite rbtn p-3 px-4 rounded text-center mx-1 cursor-pointer shadow-lg">
                                            <a href="https://www.instagram.com">
                                                <i className="fa-brands fa-instagram text-white text-3xl"></i>
                                            </a>
                                        </div>
                                        <div className="bg-kaffirbtn hite rbtn p-3 px-4 rounded text-center mx-1 cursor-pointer shadow-lg">
                                            <a href={"https://www.facebook.com/sharer/?u=" + user.anonymous_link} target="_blank" className="btn-face m-b-20 contact100-form-btn">
                                                <i className="fa-brands fa-facebook text-white text-3xl"></i>
                                            </a>
                                        </div>
                                        <div className="bg-kaffirbtn hite rbtn p-3 px-4 rounded text-center mx-1 cursor-pointer shadow-lg">
                                            <a href="https://twitter.com/home">
                                                <i className="fa-brands fa-twitter text-white text-3xl"></i>
                                            </a>
                                        </div>
                                        <a
                                            className="bg-kaffirbtn hite rbtn p-3 px-4 rounded text-center mx-1 cursor-pointer shadow-lg"
                                            target={"_blank"}
                                            href={
                                                "https://api.whatsapp.com/send?text=Write%20anonymous%20messages%20for%20me..%20%F0%9F%98%89%20Let%20*me%20know*%20what%20you%20think%20about%20me..%20%F0%9F%98%82%E2%9D%A4%20%F0%9F%91%89%20" +
                                                user.anonymous_link
                                            }
                                        >
                                            <i className="fa-brands fa-whatsapp text-white text-3xl"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="flex justify-center w-[80%] mx-auto my-3">
                                    <button
                                        onClick={() => {
                                            logout();
                                        }}
                                        type="button"
                                        className="w-full mx-2  text-[#f82586] border border-[#f82586] hover:border-white hover:text-white  hover:bg-kaffirpurple focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2 "
                                    >
                                        Log Out
                                    </button>
                                    { user.email_verified_at == null && <button
                                    onClick={()=> verifyEmail()}
                                        type="button"
                                        className="w-full mx-2  text-[#f82586] border border-[#f82586] hover:border-white hover:text-white  hover:bg-kaffirpurple focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2 "
                                    >
                                       {Loading ? 'Loading' : 'Verify Email'} 
                                    </button>}
                                </div>
                            </div>
                            <EditProfileModal user={user} modalOpen={modalOpen} setmodalOpen={setmodalOpen} NotifySuccess={NotifySuccess} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Profile;
