import axios from 'axios'
let token =localStorage.getItem('kaffir_token');

export default {

    state: {
        endpoint:"https://api-anonymous.kaffir.ng/api/",
        // endpoint:"http://api.test/api/",
        user: null,
        token: token, 
    },
    post(data){
        return  new Promise((resolve, reject) => {
            axios.post(this.state.endpoint + data.endpoint, data.details, {
                headers: { Authorization: 'Bearer ' + this.state.token,
                }
            })
            .then((data)=>{
                resolve(data);
            })
            .catch((error)=>{
                // context.dispatch('handleError', error);
                reject(error);
            })
        });
    },
    get(url){
        return new Promise((resolve, reject) => {
            axios.get(this.state.endpoint + url, {
                headers: {
                    Authorization: 'Bearer ' + this.state.token,
                },
            })
            .then((data)=>{
                resolve(data);
            })
            .catch((error)=>{
                reject(error);
            })
        });
    }
}