import { useEffect, useState, useRef } from "react"
const useGetItem = (item) => {
    const [itemdata, setData] = useState(null);
    // const ismounted = useRef()
   
    useEffect(()=>{
        var data = localStorage.getItem(item);
        data = decodeURIComponent(data);
        data = JSON.parse(data);
        setData(data);
    }, [])
    return {itemdata}
}
 
export default useGetItem;