import { useEffect, useState } from "react";
import post from "../store/index";
import Notification from '../components/notification';
import { useParams, useNavigate, Link } from 'react-router-dom';
import useGetItem from "../store/getItem";
import axios from "axios";
import RecordView from "../components/recorder";
const Message = () => {
    const { itemdata: user } = useGetItem("kaffir_user");
    const { id } = useParams();
    let navigate = useNavigate();
    const token = { token: id };
    const [anonymous_msg, setMessage] = useState({ anonymous_msg: '', token: id, ipv4: '' });
    const [Loading, setLoading] = useState(false);
    const [NotifySuccess, setNotifySuccess] = useState({ status: false, message: "" });
    const [NotifyFailed, setNotifyFailed] = useState({ status: false, message: "" });
    const [validLink, setValidLink] = useState(true);
    const encrpyt = (e) => {
        const { name, value } = e.target;
        setMessage((prevState) => ({
            ...prevState,
            [name]: value
        }))
        return
    }
    if (user != null) {
        if (id === user.user_token) {
            navigate('/' + user.username)
        }
    }
    const getIp = async () => {

        const res = await axios.get('https://geolocation-db.com/json/')
        setMessage({ ...anonymous_msg, ipv4: window.btoa(res.data.IPv4) })
    }
    const validateLink = async () => {
        await post.post({
            endpoint: "verify-anonymous-token",
            details: token,
        }).then((resp) => {
            if (resp.data.status) {
                setValidLink(true);
            } else {
                setValidLink(false);
            }
        })
    }
    const sendMesaage = () => {
        setLoading(true);
       
       
        post.post({
            endpoint: "message",
            details: anonymous_msg,
        }).then((data) => {
            if (data.data) {
                setNotifySuccess({
                    ...NotifySuccess,
                    status: true,
                    message: 'message sent succesfully'
                })
                setTimeout(() => {
                    setNotifySuccess({
                        ...NotifySuccess,
                        status: false,
                    })
                }, 3000);
                setLoading(false);
                navigate('/your-turn')
            } else {
                setNotifyFailed(
                    {
                        ...NotifyFailed,
                        status: true,
                        message: "Connection error"
                    })
                setTimeout(() => {
                    setNotifyFailed(
                        {
                            ...NotifyFailed,
                            status: false,
                        })
                }, 3000);
            }
        }).catch((error) => {
            setLoading(false);
            setNotifyFailed(
                {
                    ...NotifyFailed,
                    status: true,
                    message: "this link has been revoked or changed. Request for a new one from your friend"
                })
            setTimeout(() => {
                setNotifyFailed(
                    {
                        ...NotifyFailed,
                        status: false,
                    })
            }, 3000);
            setLoading(false);
        });
    };
    // const start = () => {
    //     setRecordState(RecordState.start);
    //     console.log('start');
    // }
    // const stop = () => {
    //     setRecordState(RecordState.stop);
    //     console.log('stop');

    // }
    // const onStop = (audioData) => {
    //     console.log(audioData);

    // }
    useEffect(() => {
        getIp();
        validateLink();
    }, [])
    return (
        <div className="bg-[#26066f] min:h-[100vh] h-[100%] py-9">
            <div className="bg-white m-auto w-[95%] md:w-[70%] lg:w-[500px] py-4 justify-center h-fit rounded-lg card-jump-2">

                <div className="w-[80%] justify-center m-auto">
                    <Notification notifyFailed={NotifyFailed} notifySuccess={NotifySuccess} />
                    <div className="pt-10">
                        <img src={require('../assets/img/anonymous.png')} className="mx-auto w-[120px]" alt="avarter/" />
                    </div>
                    <div>
                        {!validLink && <div className="text-center my-2">
                            <h1 className=" text-xl font-semibold text-kaffirpurple">This Anonymous Address has been revoked or changed by the user, Request for the latest one</h1>
                            <Link to="/signin" className="text-kaffirbtn border-b p-1 border-kaffirbtn text-xs underline underline-offset-3 touch-pan-up">Sign In now to know what your friends thinks about you</Link>
                        </div>

                        }
                        {validLink &&
                            <div className="valid-link">
                                <p className=" my-5 text-[15px] text-sans text-[#351878] text-center">Hola!! Drop a message for your friend.</p>
                                <form onSubmit={(e) => { e.preventDefault(); sendMesaage() }}>
                                    <div className="h-fit  mb-6">
                                        <textarea
                                            required
                                            class=" text-kaffirpurple form-control block w-full px-3 py-1.5
                                             text-base font-normal bg-white bg-clip-padding border-none focus:bottom-0 
                                             text-center rounded transition ease-in-out m-0"
                                            id="exampleFormControlTextarea1"
                                            rows="3"
                                            maxLength={200}
                                            placeholder="Type your message..."
                                            onChange={e => encrpyt(e)} name="anonymous_msg" >
                                        </textarea>
                                        {/* recording section */}
                                        {/* <RecordView/> */}
                                    </div>
                                    <div className="flex mb-3">
                                        <input type="checkbox" className="border-2 border-[#26066E] rounded-md h-5 w-5 " required />
                                        <p className="pl-2 text-sm text-sans text-[#26066E]">I agree to all terms and conditions</p>
                                    </div>
                                    <Link to="/signup" className="pl-2 text-sm text-sans text-[#f4547f] my-3 block">--Create your own anonymous account--</Link>

                                    {!Loading &&

                                        <button type="submit" className="text-lg py-3 w-full text-white mr-2 md:mr-4 bg-gradient-to-r rounded-md from-[#eb2384] to-[#350970]  flex justify-center ">
                                            <i class="fa-regular fa-paper-plane-top"></i>
                                            Send message

                                        </button>
                                    }
                                    {Loading &&
                                        <button type="submit" className="text-lg py-3 w-full text-white mr-2 md:mr-4 bg-gradient-to-r rounded-md from-[#eb2384] to-[#350970]  flex justify-center ">
                                            Sending...
                                        </button>
                                    }
                                    <p className="text-sm py-6 ">
                                        Simply type your message,keep it clean,we all know there is a thin line between constructive criticism and intended harassment make sure your messages are one that uplift your loved ones and give them reason to show up everyday because at Kaffir we are all about love and light😊 Compliment that special crush you have been afraid to approach for a long while at least he/she knows that someone out there cares or also as a team leader, project manager, CEO in your company and you want to know how  your colleagues/coworkers feel about you. Don't forget no one will know it was you. Abusive comments are no way allowed because every human deserves to be respected and approached in the right way and here at Kaffir we are 100% against sexual harassment😇
                                    </p>
                                </form>
                            </div>
                        }

                    </div>
                </div>

            </div>
        </div>
    );
}

export default Message;

