import { useState } from 'react';
import post from "../../store/index";
import Notification from '../../components/notification';
import { Link, useParams,useNavigate } from 'react-router-dom';


const NewPassword = ()=> {
    const{id} = useParams();
    const [User, setUser]= useState({
        password:'',
        password_confirmation:'',
        token:id
    })
    const [Loading, setLoading] = useState(false);
    const [NotifySuccess, setNotifySuccess]= useState({status:false, message:""})
    const [NotifyFailed, setNotifyFailed]= useState({status:false, message:""})

        const handleInputChange =(e)=>{
        const {name, value} = e.target;
        setUser((prevState)=>({
            ...prevState,
            [name]:value
        }))
    }

    const NewPassword = () => {
        setLoading(true);
        if (User.password_confirmation != User.password) {
            setLoading(false);
            setNotifyFailed(
                {
                    ...NotifyFailed,
                    status:true,
                    message:'Password and password confirm must match'
                })
                setTimeout(() => {
                    setNotifyFailed(
                        {
                            ...NotifyFailed,
                            status:false,
                        })
                   }, 3000);
            return;
           }
        
        post.post({
            endpoint: "reset-password",
            details: User,
        }).then((data) => {
            if (data.data.status) {
                setNotifySuccess({
                    ...NotifySuccess,
                    status:true,
                    message:data.data.message
                })
                localStorage.clear();
                window.location.href="/signin"
                setTimeout(() => {
                    setNotifySuccess({
                        ...NotifySuccess,
                        status:false,
                    })
                }, 3000);
            
            setLoading(false);
            }else{
            setLoading(false);

            setNotifyFailed(

                {
                    ...NotifyFailed,
                    status:true,
                    message:data.data.message
                })
                setTimeout(() => {
                    setNotifyFailed(
                        {
                            ...NotifyFailed,
                            status:false,
                        })
                   }, 3000);
                }
            }).catch((error) => {
                setNotifyFailed(
                    {
                        ...NotifyFailed,
                        status:true,
                        message:error.message
                    })
                    setTimeout(() => {
                        setNotifyFailed(
                            {
                                ...NotifyFailed,
                                status:false,
                            })
                }, 3000);
            setLoading(false);

                var error_message = error.response.data.message;
                    if(error_message){
                        setNotifyFailed(
                            {
                                ...NotifyFailed,
                                status:true,
                                message:error_message
                            })
                            setTimeout(() => {
                                setNotifyFailed(
                                    {
                                        ...NotifyFailed,
                                        status:false,
                                    })
                        }, 3000);
                    }
                      
               
            });
    };
    return (
            
        <div className="signup-container h-[100vh] md:bg-white bg-kaffirpurple ">
        <div className="grid md:grid-cols-2">
        <Notification notifyFailed={NotifyFailed} notifySuccess={NotifySuccess} />

            <div className="left-banner bg-kaffirpurple p-6 flex justify-center items-center hide h-[100vh] min-h-full">
                <img src={require('../../assets/img/welcome2.png')} className="w-[500px]" alt="logoage"></img>
                    
            </div>
            <div className="form-section flex flex-col justify-center items-center  p-2 mt-[50px] ">
                <div className='flex flex-col justify-center items-center max-w-[500px] bg-white w-[95%] lg:w-[60%] mt-9 p-6 shadow-lg mx-auto rounded card-jump' >
                    <div className="">
                        <img src={require('../../assets/img/anonymous.png')} className="w-[100px] mb-9" alt="logoage"></img>
                    </div>
                    <div className='w-[100%]'>
                        <form onSubmit={(e)=>{
                            e.preventDefault();
                            NewPassword(e);
                        }}>
                        <div className="mb-5">
                            <label htmlFor="password" className='text-kaffirpurple mb-2 text-lg capitalize'>New password</label>
                            <input type="password" id="password" value={User.password} onChange={handleInputChange} name="password"  className="px-4 placeholder-kaffirpurple text-kaffirpurple border-none bg-[#f3f3f3] text-sm rounded-[0px]  focus:ring-blue-500 focus:border-blue-500 block w-full p-2 py-4" placeholder='Xyz@gmail.com' required/>
                        </div>
                        <div className="mb-5">
                            <label htmlFor="password_confirmation" className='text-kaffirpurple mb-2 text-lg capitalize'>Confirm Password</label>
                            <input type="password" id="password_confirmation" value={User.password_confirmation} onChange={handleInputChange} name="password_confirmation"  className="px-4 placeholder-kaffirpurple text-kaffirpurple border-none bg-[#f3f3f3] text-sm rounded-[0px]  focus:ring-blue-500 focus:border-blue-500 block w-full p-2 py-4" placeholder='Xyz@gmail.com' required/>
                        </div>
                       
                        {
                            Loading && 
                             <div className="mb-6">
                             <input type="button" id="submit" value='Loading...' className="btn-gradient border-none text-white border-kaffirpurple text-sm rounded-[5px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2 py-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  disabled/>
                        </div>
                        }
                         {
                            !Loading && 
                             <div className="mb-1">
                             <input type="submit" id="submit" value="Reset" className="btn-gradient border-noneborder-kaffirpurple text-white text-sm rounded-[5px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2 py-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>
                        }
                        <div className='flex justify-between my-3'>
                            <Link to="/signup" className="text-sm text-kaffirpurple">Don't have an account?</Link>
                            <Link to="/signin" className="text-sm text-kaffirpurple">Login instead?</Link>
                        </div>
                       
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
 
export default NewPassword;