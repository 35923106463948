import useGetItem from "../store/getItem";


const Welcome = () => {
    const { itemdata: user } = useGetItem("kaffir_user");
    const greetings = ()=>{
            var hour = Date.now().hour;
            if (hour < 12) {
              return 'Morning';
            }
            if (hour < 17) {
              return 'Afternoon';
            }
            return 'Evening';
          }
    const time = greetings();
    return (
        <div className="bg-kaffirpurple text-white p-0 h-fitcontent w-fit-content welcome-box overflow-hidden">
            <h1 className="text-[35px] font-bold">Good {time} {user ? <h1>{user.username}</h1>: <h1>Guest</h1>}</h1>
            
        </div>
    );
}
 
export default Welcome;