import { Link } from "react-router-dom";
import useGetItem from "../store/getItem";

const Header = () => {
    const { itemdata: user } = useGetItem("kaffir_user");

    return (
        <div className="bg-kaffirpurple sticky top-0 z-10 py-0">
            <div className="maxWidth flex flex-wrap justify-between items-center mx-auto px-0 ">
                <Link to="/" className="flex items-center ez-animate my-3" data-animation="fadeInDown">
                    <img src={require('../assets/img/kaffirlogo.png')} className="mr-3 w-[45px]" alt="Flowbite Logo" />
                </Link>
                <button
                    data-collapse-toggle="mobile-menu"
                    type="button"
                    className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 xt-gray-400 ver:bg-gray-700 cus:ring-gray-600"
                    aria-controls="mobile-menu"
                    aria-expanded="false"
                >
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
                    </svg>
                    <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>
              
                <div className="hidden w-full md:block md:w-auto ez-animate" id="mobile-menu" data-animation="fadeInDown">
                    <ul className="flex flex-col md:items-center mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
                        <li>
                            <Link
                                to="#about"
                                className="block py-2 pr-4 pl-3 text-white border-b border-gray-100 hover:bg-kaffirbtn md:hover:bg-transparent md:border-0 md:hover:text-kaffirbtn md:p-0 xt-gray-400 md:ver:text-white ver:bg-gray-700 ver:text-white md:ver:bg-transparent rder-gray-700"
                            >
                                About
                            </Link>
                        </li>
                        {user &&
                         <li>
                         <Link
                         target={'_blank'}
                             to={"/" +user.username}
                             className="block py-2 pr-4 pl-3 text-white border-b border-gray-100 hover:bg-kaffirbtn md:hover:bg-transparent md:border-0 md:hover:text-kaffirbtn md:p-0 xt-gray-400 md:ver:text-white ver:bg-gray-700 ver:text-white md:ver:bg-transparent rder-gray-700"
                         >
                             Profile
                         </Link>
                     </li>
                        }
                      
                        <li>
                            <Link
                                to="/signin"
                                className="block py-2 pr-4 pl-3 text-white border-b border-gray-100 hover:bg-kaffirbtn md:hover:bg-transparent md:border-0 md:hover:text-kaffirbtn md:p-0 xt-gray-400 md:ver:text-white ver:bg-gray-700 ver:text-white md:ver:bg-transparent rder-gray-700"
                            >
                                Signin
                            </Link>
                        </li>
                        <li className="md:ml-[30px] md:mt-0 mt-[30px] " >
                            <Link
                                to="signup"
                                className="md:ml-[45px]  -gray-700 md:block py-4 rounded-sm px-5 shadow-sm text-white text-xs bg-kaffirbtn2 hover:bg-white md:border-0 md:hover:text-kaffirbtn  md:ver:text-white ver:bg-gray-700 ver:text-white md:ver:bg-transparent"
                            >
                                Get Started
                            </Link>
                        </li>
                  
                    </ul>
                </div>
            </div>
            </div>
    );
};

export default Header;
