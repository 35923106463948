import { useState, useEffect } from 'react';
import Notification from '../../components/notification';
import { Link, useParams } from 'react-router-dom';
import store from "../../store/index";
const ForgotPassword = () => {
    const [User, setUser] = useState({
        email: '',
    })
    const { id } = useParams();
    const [Loading, setLoading] = useState(false);
    const [NotifySuccess, setNotifySuccess] = useState({ status: false, message: "" })
    const [NotifyFailed, setNotifyFailed] = useState({ status: false, message: "" })
    const verifyEmail = () => {
        setLoading(true);
        store.post({
            endpoint: "verify-email/" + id,
            details: User,
        }).then((resp) => {
            setNotifySuccess({ status: true, message: "Email verified successfully" })
            setLoading(false);

            console.log(resp);
        }).catch((error) =>{
            setNotifyFailed({ status: true, message: "Email verification failed please try again" });
            setLoading(false);
        })
    }
    useEffect(() => {
        verifyEmail()
    }, []);
    return (

        <div className="signup-container h-[100vh] md:bg-white bg-kaffirpurple ">
            <div className="grid md:grid-cols-2">
                <Notification notifyFailed={NotifyFailed} notifySuccess={NotifySuccess} />

                <div className="left-banner bg-kaffirpurple p-6 flex justify-center items-center hide h-[100vh] min-h-full">
                    <img src={require('../../assets/img/welcome2.png')} className="w-[500px]" alt="logoage"></img>

                </div>
                <div className="form-section flex flex-col justify-center items-center  p-2 mt-[50px] ">
                    <div className='flex flex-col justify-center items-center max-w-[500px] w-[95%] bg-white lg:w-[60%] mt-9 p-6 shadow-lg mx-auto rounded card-jump' >
                        <div className="">
                            <img src={require('../../assets/img/anonymous.png')} className="w-[100px] mb-9" alt="logoage"></img>
                        </div>
                        <div className='w-[100%]'>
                            {
                                Loading &&
                                <div className="mb-6">
                                    <input type="button" value='Loading...' className="btn-gradient border-none  border-kaffirpurple text-white text-sm rounded-[5px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2 py-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" disabled />
                                </div>
                            }
                            {
                                !Loading &&
                                <div className="mb-1">
                                    <input type="button" disabled value="Your Email Has Been Verified" className="btn-gradient border-noneborder-kaffirpurple text-white text-sm rounded-[5px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2 py-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                </div>
                            }
                            <div className='flex justify-center my-3'>
                                <Link to="/signin" className="text-sm text-kaffirpurple">Go to login?</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;