const Report = () => {
    
    return ( 
        <div className="bg-[#26066f]">
    <div  className="">
        <div className="bg-white m-auto md:my-[7%] w-[95%] md:w-[45%] h-[80%] md:h-[70%] justify-center">

            <div className="w-[80%] justify-center m-auto">    

                <div>
                    <button className="bg-[#26066e] mt-10 place-items-center mx-[10%] sm:mx-[15%] md:mx-[35%] w-[80%] sm:w-[70%] md:w-[30%] rounded-md text-sans p-2 text-2xl text-white">Report</button>
                </div>
                    
                <div className="text-center ">
                    <p className=" my-5 text-2xl text-sans text-[#351878] ">Why are you reporting this message?</p>
                </div>

                <input type="text" placeholder="Typing....." className="my-5 h-20 w-full  border-2 rounded-md border-[#351878] placeholder:text-[#351878] placeholder:text-2xl"/>
                <button className="bg-gradient-to-r rounded-md from-[#eb2384] to-[#350970] h-20 text-2xl text-white w-full">Submit</button>

            </div>
        </div>


    </div>    

</div>
    );
}
 
export default Report;


