import { useState, useEffect } from 'react';
import { Link , useNavigate} from 'react-router-dom';
import post from "../../store/index";
import Notification from '../../components/notification';
import moment from "moment-timezone";
import axios from 'axios';


const Signup = ()=> {
    let navigate = useNavigate();
    
    const [User, setUser]= useState({
        email:'',
        username:'',
        password:'',
        PasswordConfirmation:'',
        timezone:moment.tz.guess(),
        ipv4: ''
    });

    const getIp = async () => {

        const res = await axios.get('https://geolocation-db.com/json/')
        // setUser({ ...anonymous_msg, ipv4: window.btoa(res.data.IPv4) })
        setUser((prevState)=>({
            ...prevState,
            ['ipv4']:window.btoa(res.data.IPv4) 
            
        }))
    }

    const [Loading, setLoading] = useState(false);
    const [NotifySuccess, setNotifySuccess]= useState({status:false, message:""});
    const [NotifyFailed, setNotifyFailed]= useState({status:false, message:""});

    const handleInputChange =(e)=>{
        const {name, value} = e.target;
        setUser((prevState)=>({
            ...prevState,
            [name]:value.replace(/\s/g, '')
            
        }))

    }
    
    const notAllowedUsername = [
        '@'
    ];

    const register = () => {
        setLoading(true);
       if (User.PasswordConfirmation !== User.password) {
        setLoading(false);
        setNotifyFailed(
            {
                ...NotifyFailed,
                status:true,
                message:'Password and password confirm must match'
            })
            setTimeout(() => {
                setNotifyFailed(
                    {
                        ...NotifyFailed,
                        status:false,
                    })
               }, 3000);
        return;
       }
        post.post({
            endpoint: "signup",
            details: User,
        }).then((data) => {
            if (data.data) {
                setLoading(false);
                
                var user_details = data.data.data;
                var result =  encodeURIComponent(JSON.stringify(user_details));
                var user_token= data.data.token;
                var user = user_details.username
                localStorage.setItem('kaffir_token', user_token);
                localStorage.setItem('kaffir_user', result);
                setNotifySuccess({
                    ...NotifySuccess,
                    status:true,
                    message:'welcome'
                })
                setTimeout(() => {
                    setNotifySuccess({
                        ...NotifySuccess,
                        status:false,
                    })
                }, 3000);
                navigate("/"+user)
        }
            }).catch((error) => {
            setLoading(false);
                var error_message = error.response.data.message;
                    setNotifyFailed(
                        {
                            ...NotifyFailed,
                            status:true,
                            message:error_message
                        })
                        setTimeout(() => {
                            setNotifyFailed(
                                {
                                    ...NotifyFailed,
                                    status:false,
                                })
                           }, 3000);
               
            });
    };

    useEffect(() => {
        getIp();
    }, []);
    return (
        <div className="signup-container h-[100vh] md:bg-white bg-kaffirpurple">
            <div className="grid md:grid-cols-2">
            <Notification notifyFailed={NotifyFailed} notifySuccess={NotifySuccess} />

                <div className="left-banner bg-kaffirpurple p-6 flex justify-center items-center hide h-[100vh] min-h-full">
                    <img src={require('../../assets/img/welcome2.png')} className="w-[500px]" alt="logoage"></img>
                        
                </div>
                <div className="form-section flex flex-col justify-center items-center   p-2 ">
                    <div className='flex flex-col justify-center items-center max-w-[600px] w-[95%] bg-white xl:w-[70%] mt-9 p-6 shadow-lg mx-auto rounded card-jump' >
                        <div className="">
                            <img src={require('../../assets/img/anonymous.png')} className="w-[100px] mb-9" alt="logoage"></img>
                        </div>
                        <div className='w-[100%]'>
                            <form onSubmit={(e)=>{
                                e.preventDefault();
                                register(e);
                            }}>
                                
                            <div className="mb-5">
                                <label htmlFor="email" className='text-kaffirpurple mb-2 text-sm capitalize'>Email</label>
                                <input type="email" id="email" value={User.email} onChange={handleInputChange} name="email" className="px-4 placeholder-kaffirpurple text-kaffirpurple border-none bg-[#f3f3f3] text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full py-3 rounded-sm" placeholder='Xyz@gmail.com' required/>
                            </div>
                            <div className="mb-5">
                                <label htmlFor="email" className='text-kaffirpurple mb-2 text-sm capitalize'>Username</label>
                                <input type="text" id="text" value={User.username} onChange={handleInputChange} name="username" className="px-4 placeholder-kaffirpurple text-kaffirpurple border-none bg-[#f3f3f3] text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full py-3 rounded-sm" placeholder='Mark tyson' required/>
                            </div>
                            <div className='grid grid-cols-2 gap-2'>
                            <div className="mb-5">
                                <label htmlFor="email" className='text-kaffirpurple mb-2 text-sm capitalize'>password</label>
                                <input type="password" id="password" value={User.password} onChange={handleInputChange} name="password" className="px-4 placeholder-kaffirpurple text-kaffirpurple border-none bg-[#f3f3f3] text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full py-3 rounded-sm" placeholder='*********' required/>
                            </div>
                            <div className="mb-5">
                                <label htmlFor="email" className='text-kaffirpurple mb-2   text-sm capitalize'>Confirm</label>
                                <input type="password" id="password_confirm" value={User.PasswordConfirmation} onChange={handleInputChange} name="PasswordConfirmation" className="px-4 placeholder-kaffirpurple text-kaffirpurple border-none bg-[#f3f3f3] text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full py-3 rounded-sm" placeholder='*********' required/>

                            </div>
                           
                            </div>
                            
                            {
                                Loading && 
                                 <div className="mb-6">
                                 <input type="button" id="submit" value='Loading...' className="btn-gradient border-none border-kaffirpurple text-white text-sm rounded-[5px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2 py-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  disabled/>
                            </div>
                            }
                             {
                                !Loading && 
                                 <div className="mb-1">
                                 <input type="submit" id="submit" className="btn-gradient border-noneborder-kaffirpurple text-white text-sm rounded-[5px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2 py-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                            </div>
                            }
                            <Link to="/signin" className="text-sm text-kaffirpurple">Alread have an account?</Link>
                           
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Signup;