import { useState } from "react";
import store from "../store/index";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Pagination from "react-js-pagination";
import Notification from "../components/notification";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ResponsePage = () => {
    const [NotifySuccess, setNotifySuccess] = useState({ status: false, message: "" });
    const [NotifyFailed, setNotifyFailed] = useState({ status: false, message: "" });
    const [response_id, setResponse_id] = useState(0);
    const [report_msg, setReport_msg] = useState({ message: "", message_id: response_id, hide: false, archive: false });
    const [modalOpen, setmodalOpen] = useState("hidden");
    const [responses, setResponses] = useState("");
    const { data, current_page, per_page, total } = responses ? responses : " ";
    const [Loading, setLoading] = useState(false);
    const [Error, setError] = useState(false);
    const [NoMessages, setNoMessages] = useState(false);
    let navigate = useNavigate();
    const archive = () => {
        setReport_msg({ ...report_msg, archive: !archive })
    }

    const getResponses = (pageNumber = 1) => {
        setLoading(true)
        let url = `messages?page=${pageNumber}`;
        store
            .get(url)
            .then((data) => {
                let response = data.data.data;
                if (response.data.length <= 0) {
                    setLoading(false)
                    setNoMessages(true)

                }
                setLoading(false)

                setResponses(response);

            })
            .catch((error) => {
                setLoading(false)

                var error_message = error.message;
                if (error_message.status === 401) {
                    setNotifyFailed({
                        ...NotifyFailed,
                        status: true,
                        message: 'Session expired please log in again ',
                    });
                    localStorage.removeItem('user');
                    localStorage.removeItem('kaffir_token');
                    window.location.href = "/signin";

                }
                setError(true);
                setNotifyFailed({
                    ...NotifyFailed,
                    status: true,
                    message: 'An error occured',
                });
                setTimeout(() => {
                    setNotifyFailed({
                        ...NotifyFailed,
                        status: false,
                    });
                }, 3000);
            });
    };
    const handleModalData = (data) => {
        setResponse_id(data);
        setmodalOpen("flex");
    };


    const report = () => {
        setLoading(true);

        store
            .post({
                endpoint: "report-offence",
                details: report_msg,
            })
            .then((data) => {
                if (data.data) {
                    setNotifySuccess({
                        ...NotifySuccess,
                        status: true,
                        message: "your offence report as been received",
                    });
                    setmodalOpen("hidden");
                    window.location.reload();

                    setTimeout(() => {
                        setNotifySuccess({
                            ...NotifySuccess,
                            status: false,
                        });
                    }, 3000);

                    var report_details = data.data.data;
                    var result = encodeURIComponent(JSON.stringify(report_details));
                    localStorage.setItem("reported_message", result);
                    setLoading(false);
                    navigate('/responses')
                } else {
                    setNotifyFailed({
                        ...NotifyFailed,
                        status: true,
                        message: "Connection error",
                    });
                    setTimeout(() => {
                        setNotifyFailed({
                            ...NotifyFailed,
                            status: false,
                        });
                    }, 3000);
                }
            })
            .catch((error) => {
                setLoading(false);

                var error_message = error.response.data.message;
                setNotifyFailed({
                    ...NotifyFailed,
                    status: true,
                    message: error_message,
                });
                setTimeout(() => {
                    setNotifyFailed({
                        ...NotifyFailed,
                        status: false,
                    });
                }, 3000);

                setLoading(false);
            });
    };
    const navBack = () => {
        navigate(-1)
    }
    let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    const renderList = () => {
        return (
            <div>
                {data &&
                    data.map((response) => (
                        <div key={response.id}>
                            {response.hide === '1' ? <></> : <div className="single-response border-b-2 min:h-[200px] grid sm:grid-cols-4  grid-cols-1 p-3 my-1 w-full" key={response.id}>
                                <div className="block ">
                                    <img src={require("../assets/img/anonymous.png")} className="w-[60px] mx-auto sm:mr-auto" onClick={() => navigate(-1)}></img>
                                </div>
                                <div className="flex sm:flex-nowrap flex-wrap col-span-3 sm:justify-start justify-center">
                                    <div className="text-kaffirpurple sm:text-left text-center ">
                                        <p className="border-b-2 text-semibold text-lg inline border-kaffirpurple">Message</p>
                                        <p className="text-sm py-2 sm:pr-7">
                                        {base64regex.test(response.anonymous_msg) ? window.atob(response.anonymous_msg) : response.anonymous_msg}
                                        </p>
                                        <p className="text-[13px] text-kaffirbtn time">-Anonymous-{response.created_since}</p>
                                    </div>
                                </div>
                                <div className=" md:block my-3 flex">
                                    {response.reported === '1' ?
                                        <button
                                            disabled
                                            onClick={() => archive()}
                                            id="createGroup"
                                            className="opacity-[.7] text-center pointer md:w-[90px] w-full h-9  block   hover:bg-white hover:border-2 btn-gradient border-none border-kaffirpurple text-white text-sm rounded-[5px] focus:ring-blue-500 focus:border-blue-500   mx-auto p-1  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            Reported
                                        </button>
                                        :
                                        <button
                                            onClick={() => handleModalData(response.id)}
                                            id="createGroup"
                                            className="text-center pointer md:w-[90px] block w-full h-9 hover:bg-white hover:border-2 btn-gradient border-none border-kaffirpurple text-white text-sm rounded-[5px] focus:ring-blue-500 focus:border-blue-500   mx-auto p-1  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        > Report
                                        </button>
                                    }
                                </div>
                            </div>}
                        </div>
                    ))}
            </div>
        );
    };
    useEffect(() => {
        getResponses();
        //   eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="bg-kaffirpurple p-6 px-4 min-h-[100vh]">
            <p className="text-white text-center md:text-2xl text-lg">Messages you receive will appear here</p>
            <div className="bg-white rounded-lg mx-auto my-2 md:w-[450px]  w-[95%] py-3">
                {Loading && (
                    <div className="w-[300px] mx-auto  my-9 flex justify-between items-center flex-col">
                        <Skeleton count={1} width={60} height={60} circle={true} />
                        <Skeleton count={1} width={200} />
                        <Skeleton count={1} width={250} />
                        <Skeleton count={1} width={300} />
                    </div>
                )}

                <Notification notifyFailed={NotifyFailed} notifySuccess={NotifySuccess} />
                {responses && renderList()}



                {NoMessages && (
                    <div className="text-center">
                        <i className="fa-solid fa-face-anxious-sweat text-5xl"></i>
                        <h1 className="sm:text-2xl text-xl ">Opps! you have no Meessage yet</h1>

                        <p className="text-sm">
                            share your
                            <p className="text-kaffirbtn cursor-pointer inline-block py-2" onClick={() => navBack()}>
                                Anonymous address
                            </p>
                            to your friends.
                        </p>
                    </div>
                )}
                {Error && (
                    <div className="text-center">
                        <i className="fa-solid fa-face-anxious-sweat text-5xl"></i>
                        <h1 className="text-2xl">Opps! Network Error</h1>

                        <p className="text-sm px-6 py-2">
                            We couldn't find your Messages please check your internet Connection
                        </p>
                        <Link to="" className="text-kaffirbtn" onClick={() => navBack()}>
                            Go Back
                        </Link>
                    </div>

                )}
                <div
                    id="authentication-modal"
                    tabIndex="-1"
                    aria-hidden="true"
                    className={modalOpen + "  " + "overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center"}
                >
                    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="flex justify-end p-2">
                                <button
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                                    onClick={() => {
                                        setmodalOpen("hidden");
                                        setReport_msg({ ...setReport_msg, message: " " });
                                    }}
                                >
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </button>
                            </div>
                            <form
                                className="px-6 pb-4 space-y-6 lg:px-8 sm:pb-6 xl:pb-8"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    report();
                                }}
                            >
                                <img src={require("../assets/img/anonymous.png")} className="w-[90px]" alt="anon img"></img>

                                <h3 className="text-xl font-medium text-gray-900 dark:text-white">Have you been offended by this Message?</h3>
                                <div>
                                    <label htmlFor="text" className="block mb-2  w-[20px] text-sm font-medium text-gray-900 dark:text-gray-300">
                                        Reasons:
                                    </label>
                                    <textarea
                                        type="text"
                                        name="email"
                                        id="text"
                                        value={report_msg.message}
                                        onChange={(e) => setReport_msg({ ...report_msg, message: e.target.value, message_id: response_id })}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-kaffirpurple focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                        placeholder="i am offend because...."
                                        required
                                    />
                                    <input
                                        type="number"
                                        value={response_id}
                                        onChange={() => setReport_msg({ ...report_msg, message_id: response_id })}
                                        className="opacity-0 bg-gray-50 border h-0 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-kaffirpurple focus:border-blue-500 block w-full p-0 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                        placeholder="i am offend because...."
                                    />
                                </div>
                                <div className="flex justify-between -m-top-9">
                                    <div className="flex items-start">
                                        <div className="flex items-center h-5">
                                            <input
                                                id="remember"
                                                aria-describedby="remember"
                                                type="checkbox"
                                                value={report_msg.hide}
                                                className=" w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                                                onChange={() => setReport_msg({ ...report_msg, hide: !report_msg.hide })}
                                            />
                                        </div>
                                        <div className="ml-3 text-xs">
                                            <p htmlFor="remember" className="font-medium  text-sm text-red">
                                                I do not want to see this message again!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-between -m-top-9">
                                    <div className="flex items-start">
                                        <div className="flex items-center h-5">
                                            <input
                                                id="remember"
                                                aria-describedby="remember"
                                                type="checkbox"
                                                className=" w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                                                required
                                            />
                                        </div>
                                        <div className="ml-3 text-xs">
                                            <p htmlFor="remember" className="font-medium  text-sm text-red">
                                                Agree to terms and conditions below?
                                            </p>
                                            <label htmlFor="remember" className="font-medium text-gray-900 dark:text-gray-300">
                                                Unfortunately you can't know the origin of this Message but we can help you look into it and get back to you, Also all messages are end to end encrypted "only you can see this".
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="w-full text-white btn-gradient  hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 md:py-2.5 py-4 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                >
                                    {Loading ? "Reporting...." : "Report"}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                {responses && (
                    <Pagination
                        activePage={current_page}
                        totalItemsCount={total}
                        itemsCountPerPage={per_page}
                        onChange={(pageNumber) => getResponses(pageNumber)}
                        itemClassLast="hello"
                        linkClassPrev="fa-solid fa-arrow-left"
                        linkClassNext="fa-solid fa-arrow-right"
                        prevPageText=" "
                        nextPageText=" "
                        hideFirstLastPages="false"
                    />
                )}
            </div>
        </div>
    );
};

export default ResponsePage;
