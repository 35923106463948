import { useState } from 'react';
import store from "../../store/index";
import Notification from '../../components/notification';
import { Link, useNavigate} from 'react-router-dom';
import moment from 'moment-timezone';
import useGetItem from '../../store/getItem';

const Signin = ()=> {
    const [Loading, setLoading] = useState(false);
    const [NotifySuccess, setNotifySuccess]= useState({status:false, message:""})
    const [NotifyFailed, setNotifyFailed]= useState({status:false, message:""})
    let navigate = useNavigate();

    const {itemdata:validatedUser} = useGetItem('kaffir_user')
    if (validatedUser) {
        // navigate("/"+validatedUser.username)
        window.location.href = "/"+validatedUser.username;
    }
    const [User, setUser]= useState({
        email:'',
        password:'',
        timezone:moment.tz.guess(),

    })
        const handleInputChange =(e)=>{
        const {name, value} = e.target;
        setUser((prevState)=>({
            ...prevState,
            [name]:value
        }))
    }
    const signin = () => {
        setLoading(true);
        
        store.post({
            endpoint: "login",
            details: User,
        }).then((data) => {
            if (data.data) {
                setNotifySuccess({
                    ...NotifySuccess,
                    status:true,
                    message:'welcome'
                })
               
            
            var user_details = data.data.data;
            var result =  encodeURIComponent(JSON.stringify(user_details));
            var user_token= data.data.token;
            localStorage.setItem('kaffir_token', user_token);
            localStorage.setItem('kaffir_user', result);
            var user = user_details.username
            setLoading(false);
            navigate("/"+user)
            setTimeout(() => {
                setNotifySuccess({
                    ...NotifySuccess,
                    status:false,
                })
            }, 3000);

            }else{
            setNotifyFailed(
                {
                    ...NotifyFailed,
                    status:true,
                    message:"Connection error"
                })
                setTimeout(() => {
                    setNotifyFailed(
                        {
                            ...NotifyFailed,
                            status:false,
                        })
                   }, 3000);
                }
            }).catch((error) => {
            setLoading(false);

                var error_message = error.response.data.message;
                    setNotifyFailed(
                        {
                            ...NotifyFailed,
                            status:true,
                            message:error_message
                        })
                        setTimeout(() => {
                            setNotifyFailed(
                                {
                                    ...NotifyFailed,
                                    status:false,
                                })
                           }, 3000);
               
                setLoading(false);
            });
    };
    return (
        <>
          
                <div className="signup-container bg-kaffirpurple md:bg-white h-[100vh]">
                <div className="grid md:grid-cols-2">
                <Notification notifyFailed={NotifyFailed} notifySuccess={NotifySuccess} />

                    <div className="left-banner bg-kaffirpurple p-6 flex justify-center items-center hide h-[100vh] min-h-full">
                        <img src={require('../../assets/img/welcome2.png')} className="w-[500px]" alt="logoage"></img>
                            
                    </div>
                    <div className="form-section flex flex-col justify-center items-center p-2 mt-[50px]">
                        <div className='flex flex-col justify-center items-center max-w-[500px] bg-white  w-[95%] lg:w-[70%] mt-9 p-6 shadow-lg mx-auto rounded card-jump' >
                            <div className="">
                                <img src={require('../../assets/img/anonymous.png')} className="w-[100px] mb-9" alt="logoage"></img>
                            </div>
                            <div className='w-[100%]'>
                                <form onSubmit={(e)=>{
                                    e.preventDefault();
                                    signin(e);
                                }}>
                                <div className="mb-5">
                                    <label htmlFor="email" className='text-kaffirpurple mb-2 text-sm capitalize'>Email</label>
                                    <input type="email" id="email" value={User.email} onChange={handleInputChange} name="email" className="px-4 placeholder-kaffirpurple text-kaffirpurple border-none bg-[#f3f3f3] text-sm rounded-[0px]  focus:ring-blue-500 focus:border-blue-500 block w-full py-3" placeholder='Xyz@gmail.com' required/>
                                </div>
                            
                                <div className="mb-5">
                                    <label htmlFor="email" className='text-kaffirpurple mb-2 text-sm capitalize'>password</label>
                                    <input type="password" id="password" value={User.password} onChange={handleInputChange} name="password"  className="px-4 placeholder-kaffirpurple text-kaffirpurple border-none bg-[#f3f3f3] text-sm rounded-[0px]  focus:ring-blue-500 focus:border-blue-500 block w-full py-3" placeholder='*********' required/>
                                </div>
                            
                                {
                                    Loading && 
                                    <div className="mb-6">
                                    <input type="button" id="submit" value='Loading...' className="btn-gradient border-none  border-kaffirpurple text-white text-sm rounded-[5px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2 py-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  disabled/>
                                </div>
                                }
                                {
                                    !Loading && 
                                    <div className="mb-1">
                                    <input type="submit" id="submit" className="btn-gradient border-noneborder-kaffirpurple text-white text-sm rounded-[5px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2 py-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                </div>
                                }
                                <div className='flex justify-between my-3'>
                                    <Link to="/signup" className="text-sm text-kaffirpurple">Don't have an account?</Link>
                                    <Link to="/forgot-password" className="text-sm text-kaffirpurple">Forgot password?</Link>
                                </div>
                            
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        </>
    );
}
 
export default Signin;