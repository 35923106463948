import { useState } from 'react';
import YourTurnModal from '../../components/yourTurnModal';
import useGetItem from '../../store/getItem';
import Signup from './Signup';


const YourTurn = ()=> {
    const {itemdata:validatedUser} = useGetItem('kaffir_user')
    if (validatedUser) {
        window.location.href="/" + validatedUser.username;
    }
    const [modal, setModal] = useState(true);
    setTimeout(() => {
        setModal(false);
    }, 4000);
    return (
        <>
            {modal  && <YourTurnModal/>}
            <Signup/>
        </>
    )
}
 
export default YourTurn;