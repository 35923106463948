import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter, Route,Routes} from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Signup from './views/auths/Signup';
import Signin from './views/auths/Signin';
import YourTurn from './views/auths/yourTurn';
import Home from './views/home';
import 'flowbite';
import Profile from './views/profile';
import ForgotPassword from './views/auths/ResetPassword';
import VerifyEmail from './views/auths/verifyEmail';
import NewPassword from './views/auths/newPassword';
import Message from './views/message';
import ProtectRoute from './protectRoute';
import Report from './views/report';
import Responses from './views/responses';
import Welcome from '../src/components/welcome';


ReactDOM.render(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} >
            <Route path="" element={<Home />} />
            <Route path="/verify-email/:id" element={<VerifyEmail/>} />
            <Route path="/forgot-password" element={<ForgotPassword/>} />
            <Route path="/reset-password/:id" element={<NewPassword/>} />
            <Route path="/write-anonymous/:id" element={<Message/>} />
            <Route path="/signup" element={<Signup/>} />
              <Route path="/your-turn" element={<YourTurn/>} />
             <Route path="/signin" element={<Signin/>} />
             <Route path="/welcome" element={<Welcome/>} />
             <Route path="/signin/?token=:token" element={<Signin/>} />

            

            <Route element={<ProtectRoute/>}>
              <Route path="/:id" element={<Profile/>} />
              {/* <Route path="/app/profile" element={<Profile/>} /> */}
              <Route path="/responses" element={<Responses/>} />
             <Route path="/report" element={<Report/>} />
            </Route>

              
        </Route>

      </Routes>
    </BrowserRouter>,
    document.getElementById('root')
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
