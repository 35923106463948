const YourTurnModal = () => {
    return (
        <div className="absolute w-[360px] bg-[#262f58] h-[300px] rounded-lg flex flex-col justify-center shadow-lg p-4 top-[50%] -translate-y-[50%] -translate-x-[50%]  left-[50%] overflow-hidden ez-animate" data-animation='FadeInUp'>
            <img src={require('../assets/img/anonymous.png')} className="w-[100px] mx-auto"></img>
            <div className='flex justify-between absolute right-0 left-0 animate-bounce top-7'>
                <img src={require('../assets/img/ballons.png')} className="w-[100px] mx-auto"></img>
                <img src={require('../assets/img/ballons.png')} className="w-[100px] mx-auto"></img>
            </div>
            <div className='text-white text-center px-5'>
                <p className='my-2'>Good to see you here</ p>
                <p>it's your turn now signup to start receiving messages. </p>
            </div>
        </div>
    );
}
 
export default YourTurnModal;