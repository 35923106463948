import Header from "../components/Header";
import useGetItem from "../store/getItem";
import "../App.css";
import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
    return (
        <>
            <div className="home-holder bg-kaffirpurple pt-8  1f2937]">
                <Header  />
                <div className="maxWidth pb-[50px]">
                    <div className="hero grid xl:grid-cols-3 md:grid-cols-2 pt-2">
                        <div className="ez-animate hero-text sm:mt-[100px] mt-[20px]"  data-animation="fadeInLeft">
                            <div className="lg:pt-[50px] lg:col-span-2">
                            <h1 className="md:text-[50px] text-[40px] text-white">Send anonymous messages to your friends online</h1>
                                <p className="text-[#DCDCDC] text-sm lg:pr-[100px]">Kaffir is an interative anonymous messaging app where you can create a room (group chat) to chat with friends online</p>
                                <a
                                    href="#"
                                    className="block ay-700 py-4 mt-8 w-[130px] text-center rounded-sm px-5 shadow-sm text-white text-xs bg-kaffirbtn2 hover:bg-white md:border-0 md:hover:text-kaffirbtn "
                                >
                                    Download app
                                </a>
                            </div>
                        </div>
                        <div className="hero-img xl:col-span-2 ez-animate" data-animation="fadeInRight">
                            <div className="relative w-full h-full">
                                <div className="absolute top-0 right-0 bg-[#F72585] opacity-5 block h-[700px] w-[700px] rounded-[50%]">3</div>
                                <img src={require("../assets/img/phonemock.png")} alt="logoage" className="w-[400px] absolute right-[20px] heroImg "></img>
                                <div className=" absolute left-[10%] hero-rect heroImg bg-blue -bottom-[100px] bg-[#3e13ab] p-1 rounded ez-animate" data-animation="fadeInRight">
                                    <img src={require("../assets/img/anonymoussocial2.jpg")} alt="logoage" className="w-[500px] h-[300px] "></img>
                                </div>
                                {/* <div className="absolute bottom-0 left-9 bg-white   h-[400px] w-[500px] rounded">3</div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-[#560BAD]   pb-[50px] md:py-[60px] lg:min-h-[560px] min-h-[650px]">
                    <div className="block maxWidth text-white py-4">
                        <h2 className="capitalize text-[30px] ">Why you must join the society</h2>
                    </div>
                    <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-3 maxWidth md:pb-[0px] pb-[50px] py-9">
                        <div className="rounded h-[250px] bg-kaffirCard card-jump">
                            <div className="px-5 py-7 h-full">
                                <div className="bg-[#560BAD] rounded-[50%] h-[60px] w-[60px] flex justify-center items-center">
                                    <img className="w-20 h-20 rounded-full block mt-[10px]" src={require("../assets/img/newf.png")} alt="Rounded avatar" />
                                </div>
                                <p className="text-xl text-[#26066E] my-4">Anonymity</p>
                                <p className="text-xs  text-[#26066E]">Our platform ensures you privacy so that you stay anonymous everytime you send someone a secret message.</p>
                            </div>
                        </div>
                        <div className=" rounded h-[250px] bg-kaffirCard card-jump-2">
                            <div className="px-5 py-7 h-full">
                                <div className="bg-[#560BAD] rounded-[50%] h-[60px] w-[60px] flex justify-center items-center">
                                    <img className="w-20 h-20 rounded-full block mt-[10px]" src={require("../assets/img/newf.png")} alt="Rounded avatar" />
                                </div>
                                <p className="text-xl text-[#26066E] my-4">Safe & secure</p>
                                <p className="text-xs  text-[#26066E]">Safety of our users using this platform is very important to us. We have got reporting systems so that you can report anything that you do not like to see.</p>
                            </div>
                        </div>
                        <div className=" rounded h-[250px] bg-kaffirCard card-jump">
                            <div className="px-5 py-7 h-full">
                                <div className="bg-[#560BAD] rounded-[50%] h-[60px] w-[60px] flex justify-center items-center">
                                    <img className="w-20 h-20 rounded-full block mt-[10px]" src={require("../assets/img/newf.png")} alt="Rounded avatar" />
                                </div>
                                <p className="text-xl text-[#26066E] my-4">Advance features</p>
                                <p className="text-xs  text-[#26066E]">Later in the future, you will be able to create a group chat & even send a voice note & share files.</p>
                            </div>
                        </div>
                        <div className=" rounded h-[250px] bg-kaffirCard card-jump-2">
                            <div className="px-5 py-7 h-full">
                                <div className="bg-[#560BAD] rounded-[50%] h-[60px] w-[60px] flex justify-center items-center">
                                    <img className="w-20 h-20 rounded-full block mt-[10px]" src={require("../assets/img/newf.png")} alt="Rounded avatar" />
                                </div>
                                <p className="text-xl text-[#26066E] my-4">24/7</p>
                                <p className="text-xs  text-[#26066E]">If there is anything that you need help with related to our anonymous messaging & feedback platform. We are always here for you. 24 hours a day and 7 days a week.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-kaffirpurple md:px-5 py-[70px] ">
                    <h2 className="text-center text-white text-[30px] my-2 mb-6">Most frequently asked questions</h2>
                    <div className=" h-fit" id="accordion-collapse" data-accordion="collapse">

                    <div className="grid md:grid-cols-2 gap-4 maxWidth">
                            <div className="ask-tab ez-animate" data-animation="fadeInDown">
                            <h2 id="accordion-collapse-heading-1">
                                <button
                                    type="button"
                                    className="flex items-center p-0 w-full  rounded font-medium text-left focus:ring-2 focus:ring-kaffirbtn  hover:bg-gray-100 dark:bg-[#DADADA] bg-[#DADADA] text-gray-900 white"
                                    data-accordion-target="#accordion-collapse-body-1"
                                    aria-expanded="true"
                                    aria-controls="accordion-collapse-body-1"
                                >
                                    <div className="bg-kaffirbtn2 text-white  py-6 px-4 rounded-r rounded-l mr-9">
                                        <i className="fa-solid fa-question text-[30px] font-bold"></i>
                                    </div>
                                    <span className="text-kaffirpurple capitalize">How do i know i am anonymous</span>
                                </button>
                            </h2>
                            <div id="accordion-collapse-body-1" className="bg-[#DADADA] rounded  -mt-1" aria-labelledby="accordion-collapse-heading-1">
                                <div className="bg-[#26066E]  block h-[2px] md:w-[85%] w-[55%]   mx-auto"></div>
                                <div className="ay-900 flex items-center">
                                    <div className="bg-kaffirpurple text-white  py-6 px-4 rounded-r rounded-l mr-9">
                                        <i className="fa-solid fa-question text-[30px] font-bold"></i>
                                    </div>
                                    <p className="mb-2 px-4 text-kaffirpurple text-xs gray-400 ">
                                        You will surely know you are anonymous, all messages you received are sent anonymously which means you can't know the sender. you can only know the receiver which is you.
                                    </p>
                                </div>
                            </div>
                            </div>
                           <div className="ask-tab ez-animate" data-animation="fadeInDown">
                           <h2 id="accordion-collapse-heading-2">
                                <button
                                    type="button"
                                    className="flex items-center p-0 w-full  rounded font-medium text-left focus:ring-2 focus:ring-kaffirbtn  hover:bg-gray-100 dark:bg-[#DADADA] bg-[#DADADA] text-gray-900 white"

                                    data-accordion-target="#accordion-collapse-body-2"
                                    aria-expanded="true"
                                    aria-controls="accordion-collapse-body-2"
                                >
                                    <div className="bg-kaffirbtn2 text-white  py-6 px-4 rounded-r rounded-l mr-9">
                                        <i className="fa-solid fa-question text-[30px] font-bold"></i>
                                    </div>
                                    <span className="text-kaffirpurple capitalize">If i send a voicenote,won’t i be detected</span>
                                </button>
                            </h2>
                            <div id="accordion-collapse-body-2" className="bg-[#DADADA] rounded  -mt-1" aria-labelledby="accordion-collapse-heading-2">
                                <div className="bg-[#26066E]  block h-[2px] md:w-[85%] w-[55%]   mx-auto"></div>
                                <div className="ay-900 flex items-center">
                                    <div className="bg-[#560BAD] text-white  py-6 px-4 rounded-r rounded-l mr-9">
                                        <i className="fa-solid fa-question text-[30px] font-bold"></i>
                                    </div>
                                    <p className="mb-2 px-4 text-kaffirpurple text-xs gray-400 ">you can not be detected if you use our voicenote because after your voice has be recorded you will be ask to choose a robotic voice that disguise your voice from been detected its you This feature will available on future version</p>

                                </div>
                            </div>
                           </div>
                           <div className="ask-tab ez-animate" data-animation="fadeInUp">
                           <h2 id="accordion-collapse-heading-3">
                                <button
                                    type="button"
                                    className="flex items-center p-0 w-full  rounded font-medium text-left focus:ring-2 focus:ring-kaffirbtn  hover:bg-gray-100 dark:bg-[#DADADA] bg-[#DADADA] text-gray-900 white"

                                    data-accordion-target="#accordion-collapse-body-3"
                                    aria-expanded="true"
                                    aria-controls="accordion-collapse-body-3"
                                >
                                    <div className="bg-kaffirbtn2 text-white  py-6 px-4 rounded-r rounded-l mr-9">
                                        <i className="fa-solid fa-question text-[30px] font-bold"></i>
                                    </div>
                                    <span className="text-kaffirpurple capitalize">can i create a room</span>
                                </button>
                            </h2>
                            <div id="accordion-collapse-body-3" className="bg-[#DADADA] rounded  -mt-1" aria-labelledby="accordion-collapse-heading-3">
                                <div className="bg-[#26066E]  block h-[2px] md:w-[85%] w-[55%]   mx-auto"></div>
                                <div className="ay-900 flex items-center">
                                    <div className="bg-[#560BAD] text-white  py-6 px-4 rounded-r rounded-l mr-9">
                                        <i className="fa-solid fa-question text-[30px] font-bold"></i>
                                    </div>
                                    <p className="mb-2 px-4 text-kaffirpurple text-xs gray-400 ">definietly you can create a room, Every user is entitled to create a room/group chat for friends and family. This feature will available on future version</p>

                                </div>
                            </div>
                           </div>
                           <div className="ask-tab ez-animate" data-animation="fadeInUp">
                           <h2 id="accordion-collapse-heading-4">
                                <button
                                    type="button"
                                    className="flex items-center p-0 w-full  rounded font-medium text-left focus:ring-2 focus:ring-kaffirbtn  hover:bg-gray-100 dark:bg-[#DADADA] bg-[#DADADA] text-gray-900 white"
                                    data-accordion-target="#accordion-collapse-body-4"
                                    aria-expanded="true"
                                    aria-controls="accordion-collapse-body-3"
                                >
                                    <div className="bg-kaffirbtn2 text-white  py-6 px-4 rounded-r rounded-l mr-9">
                                        <i className="fa-solid fa-question text-[30px] font-bold"></i>
                                    </div>
                                    <span className="text-kaffirpurple capitalize">what if i am offend</span>
                                </button>
                            </h2>
                            <div id="accordion-collapse-body-4" className="bg-[#DADADA] rounded  -mt-1" aria-labelledby="accordion-collapse-heading-3">
                                <div className="bg-[#26066E]  block h-[2px] md:w-[85%] w-[55%]   mx-auto"></div>
                                <div className="ay-900 flex items-center">
                                    <div className="bg-[#560BAD] text-white  py-6 px-4 rounded-r rounded-l mr-9">
                                        <i className="fa-solid fa-question text-[30px] font-bold"></i>
                                    </div>
                                    <p className="mb-2 px-4 text-kaffirpurple text-xs gray-400">
                                    Unfortunately you can't know the origin of your Messages but we can help you look into it and get back to you, Also all messages are end to end encrypted "so only you can see your messages". be rest assured we do not expose your privacy.

                                    </p>
                                </div>
                            </div>
                           </div>
                          </div>
                    </div>
                </div>

                <div className="bg-[#560BAD] pt-[80px] py-[100px]">
                    <div className="maxWidth">
                        <h1 className="text-white text-center mb-4 text-lg">Download kaffir today!</h1>
                        <div className="flex justify-center items-center">
                            <div className="bg-kaffirpurple p-5 rounded mr-3">
                                <a href="#">
                                    <img alt="appp store" className="img w-[120px]" src={require("../assets/img/appstore.png")}/>
                                </a>
                            </div>
                            <div className="bg-kaffirpurple p-5 rounded">
                                <a href="#">
                                    <img alt="appp store" className="img w-[120px]" src={require("../assets/img/appstore.png")}></img>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                  <footer className="bg-[#120432] py-9">
                    <div className="flex justify-between text-white md:flex-row flex-col text-sm maxWidth">
                      <p className="text-sm">Copyright © 2021 - kaffir Tech Solutions</p>
                      <div>
                        <p className="text-sm md:mt-2 mt-10">Reach us at <a href="mailto:feedback@kaffir.ng" target='blank'>feedback@kaffir.ng</a></p>
                        <div className="flex">
                            <div className="rounded-[50%] text-white text-xl flex items-center justify-center  h-[30px] w-[30px]">
                            <i className="fa-brands fa-instagram"></i>
                          </div>
                            <div className="rounded-[50%] text-white text-xl flex items-center justify-center  h-[30px] w-[30px]">
                          <a href="https://twitter.com/Temitopeluke">
                          <i className="fa-brands fa-twitter"></i>

                          </a>

                          </div>
                            <div className="rounded-[50%] text-white text-xl flex items-center justify-center  h-[30px] w-[30px]">
                          <i className="fa-brands fa-facebook-f"></i>

                          </div>
                            <div className="rounded-[50%] text-white text-xl flex items-center justify-center  h-[30px] w-[30px]">
                          <a href="https://www.linkedin.com/company/kaffir/">
                          <i className="fa-brands fa-linkedin"></i>

                          </a>

                          </div>
                        </div>
                      </div>
                    </div>
                  </footer>
            </div>
        </>
    );
};
export default Home;
